import { createTheme } from '@mui/material/styles';
export const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(40, 18, 174, 0.5)',
      dark: 'rgba(48, 5, 70)'
    },
    info: {
      main: 'rgb(255,255,255)'
    }
  },
  components: {
    MuiChip: {
      styleOverrides: {
        colorPrimary: 'rgba(48, 5, 70)',
        colorSecondary: 'rgb(255,255,255)'
      }
    }
  },
  typography: {
    fontFamily: ['Raleway-regular', 'Raleway-bold', 'Raleway-light'].join(',')
  }
});

export const FONTS = {
  bold: 'Raleway-bold',
  regular: 'Raleway-regular',
  light: 'Raleway-light'
};
