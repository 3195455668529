import * as yup from 'yup';

export const loginValidationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .matches(/\d+/, {
      message:
        'Password  should include at least one letter, one capital letter, one number, one special character (@#$%^&*)'
    })
    .matches(/[a-z]+/, {
      message:
        'Password  should include at least one letter, one capital letter, one number, one special character (@#$%^&*)'
    })
    .matches(/[A-Z]+/, {
      message:
        'Password  should include at least one letter, one capital letter, one number, one special character (@#$%^&*)'
    })
    .matches(/[@#$%^&*]+/, {
      message:
        'Password  should include at least one letter, one capital letter, one number, one special character (@#$%^&*)'
    })
    .required('Password is required')
});
export const signupValidationSchema = yup.object({
  userName: yup
    .string()
    .matches(/^[a-zA-Z0-9]+$/, { message: 'User name  should include letters and numbers' })
    .min(4, 'UserName should be of minimum 4 characters length')
    .required('Username is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .matches(/\d+/, {
      message:
        'Password  should include at least one letter, one capital letter, one number, one special character (@#$%^&*)'
    })
    .matches(/[a-z]+/, {
      message:
        'Password  should include at least one letter, one capital letter, one number, one special character (@#$%^&*)'
    })
    .matches(/[A-Z]+/, {
      message:
        'Password  should include at least one letter, one capital letter, one number, one special character (@#$%^&*)'
    })
    .matches(/[@#$%^&*]+/, {
      message:
        'Password  should include at least one letter, one capital letter, one number, one special character (@#$%^&*)'
    })
    .required('Password is required'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), ''], 'Passwords don`t match')
});
