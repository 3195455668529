import { DeleteOutline } from '@mui/icons-material';
import { Button, Link, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../app/hooks';
import { deletePost } from '../../app/posts/posts.thunk';
import { Post } from '../../entities/Post.entity';

const PostActions: FC<Post> = (props) => {
  const { link, title } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleDeletePost = () => {
    dispatch(deletePost(props));
  };

  return (
    <Stack
      flexDirection={'row'}
      justifyContent={'space-between'}
      sx={{ width: '100%', maxWidth: '250px' }}
      alignContent={'center'}
    >
      <Button sx={{ textTransform: 'none' }} color={'primary'}>
        <Link
          href={link}
          underline="hover"
          target={'_blank'}
          align={'center'}
          sx={{ alignItems: 'center' }}
          color="primary.dark"
        >
          Read
        </Link>
      </Button>

      <Button
        onClick={handleDeletePost}
        startIcon={<DeleteOutline />}
        color={'error'}
        sx={{
          border: '1px solid red',
          borderRadius: '15px',
          fontSize: '15px',
          textTransform: 'none'
        }}
      >
        Delete
      </Button>
    </Stack>
  );
};

export default PostActions;
