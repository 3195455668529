import './App.css';

import Box from '@mui/material/Box';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import Spiner from './components/Spiner';

const App: FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        backgroundColor: 'rgb(255,255,255)'
      }}
    >
      <Outlet />
      <Spiner />
    </Box>
  );
};

export default App;
