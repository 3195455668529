import { Query } from './entities/Query.entity';

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const GET_POSTS_URL = `${SERVER_URL}/posts`;
export const GET_PAGINATION_URL = `${SERVER_URL}/posts/pagination`;
export const GET_FILTER_URL = `${SERVER_URL}/posts/filters`;
export const CREATE_POST_URL = `${SERVER_URL}/posts/create`;
export const UPDATE_POST_URL = `${SERVER_URL}/posts/update`;
export const DELETE_POST_URL = `${SERVER_URL}/posts/delete`;
export const LOGIN_URL = `${SERVER_URL}/login`;
export const SIGNUP_URL = `${SERVER_URL}/signup`;
export const CHANGE_PASSWORD_URL = `${SERVER_URL}/changepassword`;
export const PAGE_LIMIT = 20;
export const DEFAULT_QUERY = new Query(0, PAGE_LIMIT, null, null, null, null, null);
