import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import * as React from 'react';

import CategoriesFilter from './CategoriesFilter';
import CreatorsFilter from './CreatorsFilter';

const drawerWidth = 260;

type Props = {
  container: any;
  handleDrawerToggle: () => void;
  mobileOpen: boolean;
};
export default function Filters(props: Props) {
  const { container, mobileOpen, handleDrawerToggle } = props;
  const drawer = (
    <div>
      <CategoriesFilter />
      <Divider />
      <CreatorsFilter />
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, padding: '20px' }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },

          backgroundColor: 'white',
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, padding: '20px' }
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },

          height: '100%',
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: 'transparent',
            padding: '10px'
          },
          '.MuiPaper-root': { position: 'static', flexGrow: 1 }
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
