import { Stack } from '@mui/material';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAppSelector } from '../app/hooks';
import { token } from '../app/user/user.slice';
import Filters from '../components/filtersTab/Filters';
import { Header } from '../components/Header';
import SelectedFilterOptions from '../components/SelectedFilterOptions';

export const ProtectedRoute: ({ window }: { window?: () => Window }) => JSX.Element = ({
  window
}) => {
  const user = useAppSelector(token);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const container = window !== undefined ? () => window()?.document.body : undefined;
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <Header handleDrawerToggle={handleDrawerToggle} />
      <SelectedFilterOptions />

      <Stack flexDirection={'row'} alignItems={'stretch'} bgcolor={'rgb(255,255,255)'}>
        <Filters
          container={container}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />

        {user ? <Outlet /> : <Navigate to="/signup" />}
      </Stack>
    </>
  );
};
