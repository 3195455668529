import { useTheme } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';

import { useAppSelector } from '../../app/hooks';
import { user } from '../../app/user/user.slice';
import { Post } from '../../entities/Post.entity';
import { FONTS } from '../../theme';
import PostActions from './PostActions';

const PostItem: FC<Post> = (props) => {
  const { title, creator, link } = props;
  const {
    breakpoints: { down, up, between }
  } = useTheme();

  const userData = useAppSelector(user);
  return (
    <ListItem
      sx={{
        [down('md')]: {
          flexDirection: 'column'
        }
      }}
    >
      <ListItemText
        id="switch-list-label-wifi"
        primary={title}
        sx={{
          '.MuiListItemText-primary': { fontFamily: FONTS.bold },
          ' .MuiTypography-body2': { fontFamily: FONTS.regular },
          alignSelf: 'flex-start'
        }}
        secondary={
          <React.Fragment>
            <Typography
              sx={{ display: 'inline' }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              Creator:
              <Typography
                sx={{ display: 'inline', paddingLeft: 2, fontSize: 15 }}
                component="span"
                variant="body2"
                color="primary.dark"
              >
                {creator}
              </Typography>
            </Typography>
          </React.Fragment>
        }
      />

      <PostActions {...props} />
    </ListItem>
  );
};

export default PostItem;
