import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';

import { FONTS } from '../../theme';

const Header: FC<{ title: string }> = ({ title }) => {
  const {
    breakpoints: { down, up, between }
  } = useTheme();
  return (
    <Typography
      variant={'h1'}
      sx={{
        color: 'rgba(107,20,112,1)',
        fontFamily: FONTS.bold,
        [up('md')]: {
          fontSize: '40px'
        },
        [between('sm', 'md')]: {
          fontSize: '30px'
        },
        [down('sm')]: {
          fontSize: '18px'
        }
      }}
    >
      {title}
    </Typography>
  );
};

export default Header;
