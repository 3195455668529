import { Chip, Stack } from '@mui/material';
import React from 'react';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  changeQuery,
  clearSelectedFilters,
  postsCount,
  selectedFilterOptions
} from '../app/posts/posts.slice';
import { FilerKeys } from '../app/posts/types';
const SelectedFilterOptions = () => {
  const dispatch = useAppDispatch();
  const selected = useAppSelector(selectedFilterOptions);
  const count = useAppSelector(postsCount);

  const handleDelete = (el: string, optionCategory: FilerKeys) => {
    if (selected) {
      dispatch(
        changeQuery([
          optionCategory,
          selected[optionCategory]?.filter((category) => category !== el).join(',')
        ])
      );
    }
  };

  const clearSelected = () => {
    dispatch(clearSelectedFilters());
  };

  return (
    <Stack
      flexDirection={'row'}
      padding={'20px'}
      useFlexGap={true}
      gap={'20px'}
      flexWrap={'wrap'}
      alignContent={'center'}
    >
      {selected.creators?.length || selected.categories?.length ? (
        <>
          <Chip
            key={'count'}
            label={`${count} posts selected`}
            variant="outlined"
            color={'info'}
            sx={{ color: 'black', fontSize: 14 }}
          />

          <Chip
            key={'clear'}
            label={'Clear'}
            variant="outlined"
            onClick={clearSelected}
            color={'error'}
            sx={{ color: 'black' }}
          />
        </>
      ) : null}

      {selected.creators.map((value) => (
        <Chip
          key={value}
          label={value}
          variant="outlined"
          onDelete={() => handleDelete(value, 'creators')}
          color={'primary'}
          sx={{ color: 'primary.dark' }}
        />
      ))}
      {selected.categories.map((value) => (
        <Chip
          key={value}
          label={value}
          variant="outlined"
          onDelete={() => handleDelete(value, 'categories')}
          color={'primary'}
          sx={{ color: 'primary.dark' }}
        />
      ))}
    </Stack>
  );
};

export default SelectedFilterOptions;
