import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';

import { ExpandMore } from './ExpandMore';

type Props = {
  handleExpandClick: () => void;
  expanded: boolean;
  optionsCount: number;
  title: string;
};

const FilterHeader: FC<Props> = ({ handleExpandClick, expanded, optionsCount, title }) => {
  return (
    <CardActions onClick={handleExpandClick} sx={{ padding: 0 }}>
      <Typography variant="body2" color="primary.main">
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {optionsCount}
      </Typography>
      <ExpandMore expand={expanded} aria-expanded={expanded} aria-label="show more">
        <ExpandMoreIcon />
      </ExpandMore>
    </CardActions>
  );
};

export default FilterHeader;
