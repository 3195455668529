import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { token } from '../app/user/user.slice';
import Register from '../assets/images/register.png';
import LoginForm from '../components/auth/LoginForm';
const LoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tokenData = useAppSelector(token);

  useEffect(() => {
    if (tokenData) {
      navigate('/');
    }
  }, [tokenData]);

  return (
    <Stack
      sx={{
        backgroundImage: `url(${Register})`,
        height: '100vh',
        backgroundSize: 'cover'
      }}
      justifyContent={'center'}
    >
      <LoginForm />
    </Stack>
  );
};

export default LoginPage;
