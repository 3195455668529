import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import App from '../App';
import ErrorPage from '../pages/ErrorPage';
import LoginPage from '../pages/LoginPage';
import PostsPage from '../pages/PostsPage';
import { ProtectedRoute } from '../pages/ProtectedRoute';
import SignUpPage from '../pages/SignUpPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <ProtectedRoute />,
        children: [
          {
            path: '/',
            element: <PostsPage />
          }
        ]
      },
      {
        path: '/signup',
        element: <SignUpPage />
      },
      {
        path: '/login',
        element: <LoginPage />
      }
    ]
  }
]);
