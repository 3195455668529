import { CircularProgress, Fade, Stack } from '@mui/material';
import React from 'react';

import { useAppSelector } from '../app/hooks';
import { loading } from '../app/posts/posts.slice';

const Spiner = () => {
  const isLoading = useAppSelector(loading);

  return (
    <Fade in={isLoading} timeout={{ enter: 400, exit: 200 }}>
      <Stack
        sx={{
          position: 'absolute',
          zIndex: 1,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          height: isLoading ? '100%' : 0,
          width: isLoading ? '100%' : 0,
          backgroundColor: 'rgba(75,70,70,0.51)',
          transition: 0.7
        }}
        spacing={2}
        direction="row"
        justifyContent={'center'}
      >
        <CircularProgress color="primary" sx={{ marginTop: '50vh' }} />
      </Stack>
    </Fade>
  );
};

export default Spiner;
