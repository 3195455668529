export enum Sort {
  TITLE = 'title',
  DATE = 'pubDate',
  CREATOR = 'creator',
  EMPTY = ''
}

export enum AuthDataLabels {
  USERNAME = 'Username',
  EMAIL = 'Email',
  PASSWORD = 'Password',
  CONFIRM_PASSWORD = 'Confirm Password'
}
