import { createSlice } from '@reduxjs/toolkit';

import { Exception } from '../../entities/Exception';
import { RootState } from '../store';
// defaults to localStorage for web
import { UserData } from './types';
import { login, signup } from './user.thunk';

const initialState: UserData = {
  status: true,
  token: '',
  user: { email: '', userName: '' },
  errorMessage: ''
};

export const userSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    logout: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(signup.fulfilled, (state, action) => {
      if (action.payload instanceof Exception) {
        state.errorMessage = action.payload.message;
      } else {
        state.token = action.payload.token;
        state.user = action.payload.user;
        state.errorMessage = '';
      }
    });

    builder.addCase(login.fulfilled, (state, action) => {
      if (action.payload instanceof Exception) {
        state.errorMessage = action.payload.message;
      } else {
        state.token = action.payload.token;
        state.user = action.payload.user;
      }
    });
    builder.addCase(login.pending, (state, action) => {
      state.status = false;
    });
  }
});

export const { logout } = userSlice.actions;
export const token = (state: RootState) => state.user.token;
export const user = (state: RootState) => state.user.user;
export const userReducer = userSlice.reducer;
