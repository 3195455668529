import { Action, configureStore, getDefaultMiddleware, ThunkAction } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { postsReducer } from './posts/posts.slice';
import { userReducer } from './user/user.slice';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
});

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['token', 'user']
};

export const store = configureStore({
  reducer: { posts: postsReducer, user: persistReducer(userPersistConfig, userReducer) },
  middleware: customizedMiddleware
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const persistor = persistStore(store);
