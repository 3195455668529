import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { AxiosError } from 'axios';

import {
  DELETE_POST_URL,
  GET_FILTER_URL,
  GET_PAGINATION_URL,
  GET_POSTS_URL
} from '../../constants';
import { Exception } from '../../entities/Exception';
import { Post } from '../../entities/Post.entity';
import { AppDispatch, RootState } from '../store';
import { setLoading, setPage } from './posts.slice';
import { FilterOptions, Pagination } from './types';

export const getPagination = createAsyncThunk<
  {
    pages: Pagination;
    count: number;
  },
  void,
  { state: RootState }
>('get/pagination', async (_, { getState }) => {
  const response = await axios.get<{
    pages: Pagination;
    count: number;
  }>(GET_PAGINATION_URL, { params: getState().posts.query });
  return response.data;
});
export const getFilterOptions = createAsyncThunk<FilterOptions>('get/filter', async () => {
  const posts = await axios.get(GET_FILTER_URL);
  return await posts.data;
});
export const getPosts = createAsyncThunk<
  { page: number; posts: Post[] },
  void,
  { state: RootState; dispatch: AppDispatch }
>('get/posts', async (_, { getState, dispatch }) => {
  dispatch(setLoading());

  dispatch(getPagination());
  const response = await axios.get(GET_POSTS_URL, { params: getState().posts.query });
  const posts = await response.data;
  dispatch(setPage(posts.page));
  return posts;
});

export const deletePost = createAsyncThunk<Post | Exception, Post, { dispatch: AppDispatch }>(
  'delete/posts',
  async (post, { dispatch }) => {
    try {
      dispatch(setLoading());
      await axios.post(DELETE_POST_URL, post);
      dispatch(getPosts());
      dispatch(getFilterOptions());
      return post;
    } catch (e) {
      if (e instanceof AxiosError) {
        return e.response?.data as Exception;
      } else {
        return new Exception(400, 'Something wrong');
      }
    }
  }
);
