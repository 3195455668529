import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Stack } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { FC, PropsWithChildren } from 'react';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { logout, user } from '../app/user/user.slice';
import { FONTS } from '../theme';
import SearchInput from './SearchInput';

interface Props {
  window?: () => Window;
  handleDrawerToggle: () => void;
}
export const Header: FC<PropsWithChildren<Props>> = ({ window, handleDrawerToggle }) => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(user);

  const handleLogout = () => dispatch(logout());
  return (
    <AppBar
      position="static"
      sx={{ height: '64px', boxSizing: 'content-box', backgroundColor: 'primary.dark' }}
    >
      <Toolbar sx={{ boxSizing: 'border-box' }}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="open drawer"
          sx={{ mr: 2, display: { sm: 'none' } }}
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ display: { xs: 'none', sm: 'block' }, width: 260 }}
        >
          Admin
        </Typography>
        <SearchInput />

        <Stack flexDirection={'row'} padding={'0 10px '}>
          <AccountBoxRoundedIcon />
          <Typography variant={'subtitle1'} sx={{ padding: ' 0 5px' }}>
            {userData.userName}
          </Typography>
        </Stack>

        <Box sx={{ display: { md: 'flex' } }}>
          <Button
            onClick={handleLogout}
            sx={{
              color: 'white',
              textTransform: 'capitalize',
              fontFamily: FONTS.bold,
              backgroundColor: 'rgb(98,46,128)'
            }}
          >
            Logout
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
