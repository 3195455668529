import { Button, Link, Stack, TextField, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { token } from '../../app/user/user.slice';
import { signup } from '../../app/user/user.thunk';
import { AuthDataLabels } from '../../enums';
import Header from './Header';
import { signupValidationSchema } from './Vlidation';

export const SignUpForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tokenData = useAppSelector(token);
  const error = useAppSelector((state) => state.user.errorMessage);
  const {
    breakpoints: { down, up, between }
  } = useTheme();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      userName: '',
      confirmPassword: ''
    },
    validationSchema: signupValidationSchema,
    onSubmit: (values) => {
      dispatch(
        signup({ email: values.email, password: values.password, userName: values.userName })
      );
    },
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true
  });

  useEffect(() => {
    if (tokenData) navigate('/');
  }, [tokenData]);
  const navigateToLoginPage = () => navigate('/login', { replace: true });
  return (
    <Stack
      component={'form'}
      onSubmit={formik.handleSubmit}
      sx={{
        width: '50%',
        minWidth: '250px',
        maxWidth: '400px',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        borderRadius: '52px',
        [up('md')]: {
          padding: '60px'
        },
        [between('sm', 'md')]: {
          padding: '40px'
        },
        [down('sm')]: {
          padding: '25px'
        }
      }}
      spacing={2}
      alignSelf={'center'}
      autoComplete="new-password"
    >
      <Header title={'Signup'} />
      <Typography sx={{ color: 'red', textAlign: 'center' }} variant={'caption'}>
        {error}
      </Typography>
      <TextField
        fullWidth
        id="userName"
        name="userName"
        label={AuthDataLabels.USERNAME}
        value={formik.values.userName}
        onChange={formik.handleChange}
        error={formik.touched.userName && Boolean(formik.errors.userName)}
        helperText={formik.touched.userName && formik.errors.userName}
        autoComplete="new-userName"
        InputLabelProps={{
          shrink: true
        }}
      />

      <TextField
        fullWidth
        id="email"
        name="email"
        label={AuthDataLabels.EMAIL}
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        InputLabelProps={{
          shrink: true
        }}
      />
      <TextField
        fullWidth
        id="password"
        name="password"
        label={AuthDataLabels.PASSWORD}
        type="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        InputLabelProps={{
          shrink: true
        }}
      />
      <TextField
        fullWidth
        name="confirmPassword"
        label={AuthDataLabels.CONFIRM_PASSWORD}
        type="password"
        value={formik.values.confirmPassword}
        onChange={formik.handleChange}
        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
        InputLabelProps={{
          shrink: true
        }}
      />

      <Button
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        sx={{
          background: 'radial-gradient(109.81% 200.09% at 14.95% 0%, #ED42DB 0%, #01BBE1 100%)',
          borderRadius: '26px',
          width: '50%',
          minWidth: '200px'
        }}
      >
        Submit
      </Button>

      <Link onClick={navigateToLoginPage} sx={{ cursor: 'pointer' }}>
        Have an account? Login.
      </Link>
    </Stack>
  );
};
