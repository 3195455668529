import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { CHANGE_PASSWORD_URL, LOGIN_URL, SIGNUP_URL } from '../../constants';
import { Exception } from '../../entities/Exception';
import { TokenEntity } from '../../entities/Token.entity';
import { User } from '../../entities/User.entity';
import { FilterOptions } from '../posts/types';

export const login = createAsyncThunk<
  (TokenEntity & { user: Pick<typeof User.prototype, 'email' | 'userName'> }) | Exception,
  Pick<User, 'password' | 'email'>
>('user/login', async (user) => {
  try {
    console.log('logging', LOGIN_URL);
    const response = await axios.post(LOGIN_URL, user);
    console.log(response);
    return await response.data;
  } catch (e) {
    console.log(e);
    if (e instanceof AxiosError) {
      return e.response?.data as Exception;
    }
  }
});
export const signup = createAsyncThunk<
  (TokenEntity & { user: Pick<typeof User.prototype, 'email' | 'userName'> }) | Exception,
  User
>('user/signup', async (user) => {
  try {
    const response = await axios.post(SIGNUP_URL, user);

    return await response.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      return new Exception(e.response?.data.code, e.response?.data.message);
    }
  }
});
export const changePassword = createAsyncThunk<FilterOptions, string>(
  'user/changepassword',
  async (password) => {
    const token = localStorage.token;
    const response = await axios.post(CHANGE_PASSWORD_URL, {
      headers: { Authorization: `Bearer ${token}` },
      password
    });
    return await response.data;
  }
);
