import { Stack } from '@mui/material';
import React from 'react';

import Register from '../assets/images/register.png';
import { SignUpForm } from '../components/auth/SignUpForm';

const SignUpPage = () => {
  return (
    <Stack
      sx={{
        backgroundImage: `url(${Register})`,
        height: '100vh',
        backgroundSize: 'cover'
      }}
      justifyContent={'center'}
    >
      <SignUpForm />
    </Stack>
  );
};

export default SignUpPage;
