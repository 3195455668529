import { Sort } from '../enums';

export class Query {
  constructor(
    public skip: number,
    public limit: number,
    public sort: Sort | null,
    public categories: string | null,
    public creators: string | null,
    public search: string | null,
    public sortOrder: 'desc' | 'asc' | null
  ) {
    this.skip = skip || 0;
    this.limit = limit || 20;
    this.sort = sort;
    this.categories = categories;
    this.creators = creators;
    this.search = search;
    this.sortOrder = sortOrder;
  }

  static getFilterKeys() {
    return ['creators', 'categories'];
  }
}
