import { Card, Checkbox, Collapse, FormControlLabel, Stack } from '@mui/material';
import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { filterOptions, handleSelect, selectedFilterOptions } from '../../app/posts/posts.slice';
import FilterHeader from './FilterHeader';
import SearchInOptions from './SearchInOptions';
const CreatorsFilter = () => {
  const dispatch = useAppDispatch();

  const creators = useAppSelector(filterOptions)?.creators;
  const selected = useAppSelector(selectedFilterOptions);
  const [filterArray, setFilterArray] = React.useState<string[]>([]);
  const handleChange = (checked: boolean, name: string) => {
    dispatch(handleSelect(['creators', name]));
  };
  const [expandedCreators, setExpandedCreators] = React.useState(false);

  const handleExpandClick = () => {
    setExpandedCreators(!expandedCreators);
  };

  const onSearchTextChange = (text: string) => {
    const regex = new RegExp(text, 'i');
    if (text.length) setFilterArray(creators?.filter((creator) => creator.match(regex)) || []);
    else setFilterArray(creators || []);
  };

  useEffect(() => {
    setFilterArray(creators || []);
  }, [creators]);

  return (
    <Card sx={{ maxWidth: 345, boxShadow: 'none', backgroundColor: 'transparent' }}>
      <FilterHeader
        handleExpandClick={handleExpandClick}
        expanded={expandedCreators}
        optionsCount={creators?.length || 0}
        title={'Creators'}
      />

      <Collapse in={expandedCreators}>
        <SearchInOptions onChange={onSearchTextChange} />
        <Stack sx={{ display: 'flex', flexDirection: 'column', overflow: 'scroll' }}>
          {filterArray?.map((name) => (
            <FormControlLabel
              key={name}
              control={
                <Checkbox
                  name={name}
                  onChange={(_, checked) => handleChange(checked, name)}
                  checked={selected?.creators.includes(name)}
                />
              }
              label={name}
              sx={{ '& >.MuiFormControlLabel-label': { hyphens: 'auto' } }}
            />
          ))}
        </Stack>
      </Collapse>
    </Card>
  );
};

export default CreatorsFilter;
