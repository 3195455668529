import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_QUERY } from '../../constants';
import { Exception } from '../../entities/Exception';
import { Post } from '../../entities/Post.entity';
import { RootState } from '../store';
import { deletePost, getFilterOptions, getPagination, getPosts } from './posts.thunk';
import { FilerKeys, PostsState, QueryKeys } from './types';
const initialState: PostsState = {
  status: true,
  posts: {},
  query: { ...DEFAULT_QUERY },
  page: 1,
  loading: false,
  count: 0,
  selectedFilterOptions: { creators: [], categories: [] },
  errorMessage: ''
};

export const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    changeQuery: (state, action: PayloadAction<[key: QueryKeys, value: string]>) => {
      const [key, value] = action.payload;
      state.query = { ...state.query, [key]: value };
      const { categories, creators } = state.query;
      state.selectedFilterOptions = {
        categories: categories ? categories?.split(',') : [],
        creators: creators ? creators?.split(',') : []
      };
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLoading: (state) => {
      console.log('start');
      state.loading = true;
    },
    handleSelect: (
      state,
      { payload: [key, value] }: PayloadAction<[key: FilerKeys, value: string]>
    ) => {
      state.selectedFilterOptions = {
        ...state.selectedFilterOptions,
        [key]: state.selectedFilterOptions[key].includes(value)
          ? state.selectedFilterOptions[key].filter((el) => el !== value)
          : [...state.selectedFilterOptions[key], value]
      };
      state.query[key] = state.selectedFilterOptions[key].join(',');
    },
    clearSelectedFilters: (state) => {
      state.selectedFilterOptions = initialState.selectedFilterOptions;
      state.query.creators = '';
      state.query.categories = '';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPosts.fulfilled, (state, action) => {
      state.posts[action.payload.page] = action.payload.posts;
      state.loading = false;
    });
    builder.addCase(deletePost.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePost.fulfilled, (state, action) => {
      if (action.payload instanceof Exception) {
        state.errorMessage = action.payload?.message || 'Something wrong';
        state.loading = false;
      } else {
        state.posts[state.page] = state.posts[state.page].filter(
          (el) => el.link !== (action.payload as typeof Post.prototype).link
        );
        state.loading = false;
      }
    });

    builder.addCase(getPagination.fulfilled, (state, action) => {
      state.pagination = action.payload.pages;
      state.count = action.payload.count;
    });
    builder.addCase(getFilterOptions.fulfilled, (state, action) => {
      state.filterOptions = action.payload;
    });
  }
});
export const { changeQuery, setPage, setLoading, clearSelectedFilters, handleSelect } =
  postsSlice.actions;
export const status = (state: RootState) => state.posts.status;
export const postsCollection = (state: RootState) => state.posts.posts;
export const pagination = (state: RootState) => state.posts.pagination;
export const query = (state: RootState) => state.posts.query;
export const filterOptions = (state: RootState) => state.posts.filterOptions;
export const selectedFilterOptions = (state: RootState) => state.posts.selectedFilterOptions;
export const page = (state: RootState) => state.posts.page;
export const loading = (state: RootState) => state.posts.loading;
export const postsCount = (state: RootState) => state.posts.count;
export const error = (state: RootState) => state.posts.errorMessage;
export const postsReducer = postsSlice.reducer;
