import { Pagination } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import React, { ChangeEvent, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  changeQuery,
  error,
  filterOptions,
  page,
  pagination,
  postsCollection,
  query,
  setLoading,
  setPage
} from '../app/posts/posts.slice';
import { getFilterOptions, getPosts } from '../app/posts/posts.thunk';
import PostItem from '../components/posts/PostItem';
import { PAGE_LIMIT } from '../constants';

const PostsPage = () => {
  const dispatch = useAppDispatch();
  const posts = useAppSelector(postsCollection);
  const requestQuery = useAppSelector(query);
  const pages = useAppSelector(pagination);
  const currentPage = useAppSelector(page);
  const errorMessage = useAppSelector(error);
  const onPageChange = (_event: ChangeEvent<unknown>, pageNumber: number) => {
    const skip = (pageNumber - 1) * PAGE_LIMIT;
    dispatch(changeQuery(['skip', String(skip)]));
    if (!posts[pageNumber]?.length) {
      dispatch(getPosts());
    } else {
      dispatch(setPage(pageNumber));
    }
  };

  const filtersOptions = useAppSelector(filterOptions);

  useEffect(() => {
    if (!filtersOptions) {
      dispatch(setLoading());
      dispatch(getFilterOptions());
    }
  }, [filtersOptions]);

  useEffect(() => {
    if (!Object.entries(posts).length) {
      dispatch(getPosts());
    }
  }, [posts]);

  useEffect(() => {
    dispatch(getPosts());
  }, [requestQuery.categories, requestQuery.creators]);

  return (
    <List
      sx={{
        width: '100%',
        flexGrow: 1,
        paddingBottom: '15px',
        overflow: 'scroll'
      }}
    >
      <Box color={'red'} textAlign={'center'}>
        {errorMessage}
      </Box>
      {posts[currentPage]?.map((el) => (
        <PostItem key={el.link} {...el} />
      ))}
      <ListItem sx={{ justifyContent: 'center' }}>
        <Pagination
          count={Object.entries(pages || {}).length || 1}
          variant="outlined"
          color="primary"
          sx={{ '.MuiPagination-ul': { justifyContent: 'center' } }}
          page={currentPage}
          onChange={onPageChange}
        />
      </ListItem>
    </List>
  );
};

export default PostsPage;
