import { TextField } from '@mui/material';
import React, { FC } from 'react';

type Props = {
  onChange: (value: string) => void;
};
const SearchInOptions: FC<Props> = ({ onChange }) => {
  return (
    <TextField
      id="outlined-basic"
      placeholder="Search"
      variant="outlined"
      sx={{
        '.MuiOutlinedInput-input': { padding: '5px 10px', '&::placeholder': { fontSize: '12px' } }
      }}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};

export default SearchInOptions;
